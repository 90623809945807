
/* App.scss */
.App {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100vh; /* Ensure the App container takes full viewport height */

}

.page {
  width: 93%;
  height: 100%;
   /* Enable vertical scroll on the page content */
  padding: 0px 20px; /* Add some padding for content */
  box-sizing: border-box;
  overflow-y: scroll;
}

.container {
  width: 100%;
  
  opacity: 0;
  margin: 0 auto;
  animation: fadeIn 0.4s forwards;
  animation-delay: 0.4s;
}

/* Example of a basic fadeIn animation */
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
