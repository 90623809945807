/* styles.css */
.blog-list {
  position: relative;
  display: grid;
  row-gap:20px;
  h1 {
    color: #fff;
    font-size: 53px;
    margin: 0;
    font-weight: 400;
    background-image: url("../../images/background-blog.jpg");
    background-blend-mode:hue;
    text-transform: capitalize;
    text-shadow: grey 3px 3px;
    text-align: center;
    &::before {
      color: #ffd700;
      font-size: 18px;
      position: absolute;
      margin-top: 18px;
      margin-left: -60px;
      opacity: 0.6;
    }
    &::after {
      color: #ffd700;
      font-size: 18px;
      position: absolute;
      margin-top: 18px;
      margin-left: 20px;
      opacity: 0.6;
    }
  }
}

.blog-posts {
  width: 100%; /* Adjust width as needed */
  display: grid;
  grid-template-columns: auto auto auto auto;
  row-gap: 20px;
  justify-content: space-around;
}

.blog-post {
  background-color: #f9f9f9;
  height: max-content;
  width: 230px;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  text-align: center;
  h2 {
    margin-bottom: 10px;
  }
  a{
    text-decoration: none;
    color: grey;
  }
  p {
    margin-bottom: 15px;
  }
  p:nth-of-type(2) {
    text-align: justify;
  }
}



/* Show the sec-logo when the browser window is 500px or less */
@media (max-width: 500px) {
  .blog-post {
    left: 25%;
  }
}

/* Show the pri-logo when the browser window is wider than 300px */
@media (min-width: 500px) {
  .blog-post {
    left: 15%;
  }
}
