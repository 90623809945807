/* styles.css */

.contact-page .section {
  width: 50%;
  max-width: 90%;
}

.contact-page h1 {
  color: #fff;
  font-size: 53px;
  margin: 0;
  font-weight: 400;
  position: relative;
}

.contact-page form::before {
  color: #ffd700;
  font-size: 18px;
  position: absolute;
  margin-top: -40px;
  left: 15px;
  opacity: 0.6;
}

.contact-page form::after {
  color: #ffd700;
  font-size: 18px;
  position: absolute;
  margin-top: 18px;
  margin-left: 20px;
  opacity: 0.6;
  animation: fadeIn 1s 1.7s backwards;
}

/* Form Styles */
form {
  margin-top: 30px;
}

.form-group {
  margin-bottom: 20px;
}

label {
  display: block;
  margin-bottom: 5px;
  color: #fff;
  font-size: 18px;
}

input[type='text'],
input[type='email'],
textarea {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #f5f5f5;
  color: #333;
}

textarea {
  height: 150px;
}

button[type='submit'] {
  padding: 10px 20px;
  font-size: 18px;
  background-color: #ffd700;
  border: none;
  border-radius: 5px;
  color: #333;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

button[type='submit']:hover {
  background-color: #ffc107;
}

/* Show the sec-logo when the browser window is 500px or less */
@media (max-width: 500px) {
  .section {
    left: 25%;
  }
}

/* Show the pri-logo when the browser window is wider than 300px */
@media (min-width: 500px) {
  .section {
    left: 15%;
  }
}
