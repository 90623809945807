.home-page {
  height: 100vh;
  display: flex;
  flex-direction: row;
  align-items:flex-end;
  justify-content: space-between;
  .picture{
    width:auto;
    height: 100%;
    img {
      height: 100%;
      width: 100%;
      animation: zoomInDown 1s linear both,tada 2s linear both;
      animation-delay: 0.3s;
      // &:hover{
      //   animation: shakeX 1s linear both;
      // }
    }
  }
  .text{
    width: 50%;
    padding-bottom: 20px;
  
  h1 {
    color: #fff;
    font-size: 53px;
    margin: 0px;
    font-weight: 400;
    
  }
  h2 {
    color: #ffffffbb;
    margin-top: 0px;
    font-weight: 400;
    font-size: 26px;
    font-family: sans-serif;
    letter-spacing: 3px;
    animation: fadeIn 1s 1.8s backwards;
  }}

  .flat-button {
    color: #ffd700;
    background-color: transparent;
    font-size: 13px;
    font-weight: 400;
    letter-spacing: 4px;
    font-family: sans-serif;
    text-decoration: none;
    padding: 10px 18px;
    border: 1px solid #ffd700;
    margin-top: 25px;
    float: left;
    animation: fadeIn 1s1.8s backwards;
    white-space: nowrap;
    &:hover {
      background-color: #ffd700;
      color: #333;
      cursor: pointer;
    }
  }
}

/* Show the sec-logo when the browser window is 500px or less */
@media (max-width: 500px) {
  .home-page {
    .text-zone {
      width: 70%;
      left: 25%;
      h1 {
        color: #fff;
        font-size: 32px;
      }
      h2 {
        font-size: 9px;
      }
      .flat-button {
        padding: 4px 8px;
      }
    }
  }
}

/* Show the pri-logo when the browser window is wider than 300px */
@media (min-width: 500px) {
  .home-page {
    .text-zone {
      width: 50%;
      left: 15%;
      h1 {
        font-size: 53px;
        color: #fff;
      }
    }
  }
}
