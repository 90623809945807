$primary-color: #ffd700;

@import 'animate.css';

// @font-face {
//   font-family: 'Poppins Black';
//   src: url('./asstes/fonts/Poppins-Black.ttf') format('ttf');
// }

input,
textarea {
  font-family: Arial, Helvetica, sans-serif;
}
