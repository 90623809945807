.about-page {
  // margin: auto 0px;
  .text-zone {
    margin: auto 0px;
    width: 50%;
    max-width: 90%;
  }
  h1 {
    color: #fff;
    font-size: 53px;
    margin: 0;
    font-weight: 400;
  }
}
