// index.scss
.blog-post-detail {
    padding: 20px;
    margin: 10px auto;
    max-width: 1000px;
    min-height: 400px;
    border: #fff 1px solid;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    color: #fff;
  
    h2 {
      font-size: 2em;
      margin-bottom: 0.5em;
    }
  
    .time-to-read {
      margin-bottom: 1em;
      font-size: 0.9em;
      color: #888;
    }
  
    .post-image {
      width: 100%;
      height: auto;
      margin-bottom: 1em;
      border-radius: 8px;
      box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
    }
  
    .content {
      font-size: 1.2em;
      line-height: 1.6;
      margin-bottom: 1.5em;
  
      p {
        margin-bottom: 1em;
      }
  
      img {
        max-width: 100%;
        height: auto;
        margin: 1em 0;
      }
  
      blockquote {
        border-left: 5px solid #ccc;
        padding-left: 1em;
        margin: 1em 0;
        color: #666;
        font-style: italic;
      }
  
      pre {
        background: #f5f5f5;
        padding: 1em;
        border-radius: 5px;
        // overflow-x: auto;
      }
  
      code {
        font-family: 'Courier New', Courier, monospace;
        background: #f5f5f5;
        padding: 0.2em 0.4em;
        border-radius: 3px;
      }
    }
  
    .date {
      display: block;
      text-align: right;
      color: #888;
      font-size: 0.9em;
    }
  
    .tags {
      margin-top: 2em;
  
      h3 {
        margin-bottom: 1em;
        font-size: 1.5em;
      }
  
      ul {
        list-style: none;
        padding: 0;
  
        li {
          display: inline;
          margin-right: 0.5em;
          padding: 0.2em 0.5em;
          background: #f1f1f1;
          border-radius: 3px;
          font-size: 0.9em;
          color: #555;
        }
      }
    }
  
    .related-posts {
      margin-top: 2em;
  
      h3 {
        margin-bottom: 1em;
        font-size: 1.5em;
      }
  
      ul {
        list-style: none;
        padding: 0;
  
        li {
          margin-bottom: 0.5em;
  
          a {
            text-decoration: none;
            color: #007bff;
            transition: color 0.2s;
  
            &:hover {
              color: #0056b3;
            }
          }
        }
      }
    }
  
    .copy-link {
      display: inline-block;
      margin-top: 1em;
      padding: 0.5em 1em;
      background-color: #007bff;
      color: #fff;
      border: none;
      border-radius: 4px;
      cursor: pointer;
      font-size: 1em;
      transition: background-color 0.2s;
  
      &:hover {
        background-color: #0056b3;
      }
    }
  
    .review-section {
      margin-top: 2em;
  
      h3 {
        margin-bottom: 1em;
        font-size: 1.5em;
      }
  
      .form-group {
        margin-bottom: 1em;
  
        label {
          display: block;
          margin-bottom: 0.5em;
          font-weight: bold;
        }
  
        input,
        textarea {
          width: 100%;
          padding: 0.5em;
          border: 1px solid #ccc;
          border-radius: 4px;
          font-size: 1em;
        }
  
        textarea {
          resize: vertical;
          height: 100px;
        }
      }
  
      button {
        display: inline-block;
        padding: 0.5em 1em;
        background-color: #28a745;
        color: #fff;
        border: none;
        border-radius: 4px;
        cursor: pointer;
        font-size: 1em;
        transition: background-color 0.2s;
  
        &:hover {
          background-color: #218838;
        }
      }
    }
  }
  