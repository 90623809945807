
.nav-bar {
  background-color: rgba($color: #18181818, $alpha: 0.3);
  width: 7%;
  height: 100vh;
  min-height: 500px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

#logo {
  display: block;
  padding: 8px 0;
  img {
    display: block;
    margin: 8px auto;
    width: 30px;
    height: auto;
  }
}
nav {
  height: 210px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  
  button,a {
    background: none;
    outline: none;
    border: none;
    font-size: 22px;
    color: #ffffff;
    display: block;
    line-height: 51px;
    height: 51px;
    position: relative;
    i {
      transition: all 0.3s ease-out;
    }
    &:hover {
      color: #ffd700;
      svg {
        opacity: 0;
      }
      &:after {
        opacity: 1;
      }
    }
    &:after {
      content: '';
      font-size: 9px;
      letter-spacing: 2px;
      position: absolute;
      bottom: 0;
      left:-10px;
      display: block;
      width: 100%;
      text-align: center;
      opacity: 0;
      transition: all 0.3s ease-out;
    }
    &:first-child {
      &:after {
        content: 'HOME';
      }
    }
    &.about-link {
      &:after {
        content: 'ABOUT';
      }
    }
    &.blog-link {
     &:after {
        content: 'BLOG';
      }
    }
    &.contact-link {
      &:after {
        content: 'CONTACT';
      }
    }
    &.active {
      svg {
        color: #ffd700;
      }
    }
  }
}

ul {
  list-style: none;
    padding: 0px;
    margin: 0px;
    text-align: center;

  li {
    a {
      padding: 7px 0;
      display: block;
      font-size: 15px;
      line-height: 16px;
      &:hover svg {
        color: #ffd700;
      }
    }
  }
}

/* Show the sec-logo when the browser window is 500px or less */
@media (max-width: 500px) {
  #logo .pri-logo {
    width: 30px;
  }
}

/* Show the pri-logo when the browser window is wider than 300px */
@media (min-width: 500px) {
  #logo .pri-logo {
    width: 60px;
  }
}
